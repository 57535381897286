import AnalyticsApiClient from "./clients/AnalyticsApiClient";



export const getAnalyticsData = async (params) => {
    return AnalyticsApiClient({
      method: "GET",
      url: "/chat-bot/statistics",
      params
    });
  };

export const getConversationStats = async (params) => {
    return AnalyticsApiClient({
      method: "GET",
      url: "/chat-bot/conversation-statistics",
      params
    });
};

export const getConverstaionId = async (params) => {
  return AnalyticsApiClient({
    method: "GET",
    url: "/chat-bot/message/conversation",
    params
  });
};